<template>
  <v-container v-show="showForm">
    <div class="mb-10">
      <the-page-title
        title="UPITNIK ZA RODITELJE"
        :steps="steps"
      />
      <the-child-info
        :name="child_name"
        :surname="child_surname"
        :pin="child_pin"
        :email="email"
      />
    </div>

    <div class="mt-10 mb-10" v-if="form.hasOwnProperty('q_g2_title')">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">{{ form.q_g2_title.label }}</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pb-2 elevation-6 rounded fluid nbr-mw800">
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-3 mb-sm-0"
              v-if="(item.type=='select' || item.type=='multiselect')  && item.tags=='g2' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="itemLabel(index)"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
              :multiple="item.type=='multiselect'"
              :clearable="item.type=='multiselect'"
              return-object
            />
            <v-text-field
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-3 mb-sm-0"
              v-if="item.type=='text' && item.tags=='g2' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="itemLabel(index)"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @keypress.native="form[index].error=''"
            />
            <file-upload
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-8"
              v-if="item.type=='document' && item.tags=='g2' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :hash="form.hash.value"
              :max="document_list[index].max_files"
              :label="document_list[index].document_type_label"
              :error-messages="form[index].error"
              @error="showError"
              v-model="form[index].value"
              @change="form[index].error=''"
            />
          </div>
        </div>
      </v-row>
    </div>

    <div class="mt-10 mb-10" v-if="form.hasOwnProperty('q_g3_title')">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">{{ form.q_g3_title.label }}</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pb-2 elevation-6 rounded fluid nbr-mw800">
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-3 mb-sm-0"
              v-if="(item.type=='select' || item.type=='multiselect') && item.tags=='g3' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="itemLabel(index)"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              :multiple="item.type=='multiselect'"
              @change="form[index].error=''"
              return-object
            />
            <v-text-field
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-3 mb-sm-0"
              v-if="item.type=='text' && item.tags=='g3' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="itemLabel(index)"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @keypress.native="form[index].error=''"
            />
            <file-upload
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-8"
              v-if="item.type=='document' && item.tags=='g3' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :hash="form.hash.value"
              :max="document_list[index].max_files"
              :label="document_list[index].document_type_label"
              :error-messages="form[index].error"
              @error="showError"
              v-model="form[index].value"
              @change="form[index].error=''"
            />
          </div>
        </div>
      </v-row>
    </div>

    <div class="mt-10 mb-10" v-if="form.hasOwnProperty('q_g4_title')">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">{{ form.q_g4_title.label }}</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pb-2 elevation-6 rounded fluid nbr-mw800">
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-3 mb-sm-0"
              v-if="(item.type=='select' || item.type=='multiselect') && item.tags=='g4' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="itemLabel(index)"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
              return-object
            />
            <v-text-field
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-3 mb-sm-0"
              v-if="item.type=='text' && item.tags=='g4' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="itemLabel(index)"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @keypress.native="form[index].error=''"
            />
          </div>
        </div>
      </v-row>
    </div>

    <div class="mt-10 mb-10" v-if="form.hasOwnProperty('q_g5_title')">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">{{ form.q_g5_title.label }}</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pb-2 elevation-6 rounded fluid nbr-mw800">
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-3 mb-sm-0"
              v-if="(item.type=='select' || item.type=='multiselect') && item.tags=='g5' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="itemLabel(index)"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
              return-object
            />
            <v-text-field
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-3 mb-sm-0"
              v-if="item.type=='text' && item.tags=='g5' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="itemLabel(index)"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @keypress.native="form[index].error=''"
            />
          </div>
        </div>
      </v-row>
    </div>

    <div class="mt-10 mb-10" v-if="form.hasOwnProperty('q_g6_title')">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">{{ form.q_g6_title.label }}</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 pb-2 elevation-6 rounded fluid nbr-mw800">
          <div v-for="(item, index) in form" :key="item.name" :index="index">
            <v-select
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-3 mb-sm-0"
              v-if="(item.type=='select' || item.type=='multiselect') && item.tags=='g6' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="itemLabel(index)"
              :items="item.items"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @change="form[index].error=''"
              :multiple="item.type=='multiselect'"
              :clearable="item.type=='multiselect'"
              return-object
            />
            <v-text-field
              :class="{ 'ml-4': item.level == 1, 'ml-8': item.level == 2 }"
              class="mb-3 mb-sm-0"
              v-if="item.type=='text' && item.tags=='g6' && checkVisible(form[item.visible[0]].value, item.visible[1])"
              :dense="denseField"
              :outlined="outlined"
              :label="itemLabel(index)"
              v-model="form[index].value"
              :error-messages="form[index].error"
              @keypress.native="form[index].error=''"
            />
          </div>
        </div>
      </v-row>
    </div>

    <v-row class="justify-center mb-4">
      <v-card class="pa-2 elevation-6 blue nbr-mw800">
        <v-card-actions>
          <v-btn
            @click="postForm('c')"
          >Nazad</v-btn>
          <v-spacer/>
          <v-btn
            @click="postForm('dokumenti')"
          >Spremi upitnik za roditelje</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>

  </v-container>
</template>

<script>
import FileUpload from '../components/FileUpload';
import ThePageTitle from '../components/ThePageTitle';
import TheChildInfo from '../components/TheChildInfo';
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'Questionnaire',
  mixins: [ common ],
  mounted () {
    this.getFormData();
    console.log('Hash: ' + this.form.hash.value);
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
          default: return false;
        }
    }
  },

  methods: {
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getFormQuestionnaire', hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            //
            this.child_name = res.data.form_basic.child_name;
            this.child_surname = res.data.form_basic.child_surname;
            this.child_pin = res.data.form_basic.child_pin;
            this.email = res.data.form_basic.email;
            this.steps = res.data.steps;
            this.p89 = eval(res.data.p89);
            this.document_list = res.data.document_list;

            this.setFormFields(res.data.fields);

            let watch_field = {};
            let visible = null;
            // za koja polja treba watcher?
            for (let i=0; i<res.data.fields.length; i++) {
              if (res.data.fields[i].visible) {
                visible = JSON.parse(res.data.fields[i].visible);
                watch_field[visible[0]] = [];
              }
            }
            // postavi watcher na polje o čijoj vrijednosti ovise neka druga polja
            for (let i=0; i<res.data.fields.length; i++) {
              if (res.data.fields[i].visible) {
                let visible = null;
                visible = JSON.parse(res.data.fields[i].visible);
                watch_field[visible[0]].push(res.data.fields[i].name);
              }
            }
            for (const field in watch_field) {
              this.$watch('form.' + field + '.value', function() {
                if (this.init) return;
                for (let i=0; i<watch_field[field].length; i++) {
                  this.form[watch_field[field][i]].value = null;
                  //delete(this.form[watch_field[field][i]].value);
                  this.form[watch_field[field][i]].error = '';
                }
              });
            }

            this.setFormData(res.data.form_questionnaire);
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => {
          this.hideOverlay();
          this.showForm = true;
          this.init = false;
        });
    },
    postForm(route) {
      this.showOverlay();
      axios.post('', { action: 'saveFormQuestionnaire', data: this.formData() })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            console.log('Route change to: ' + 'prijava/' + this.form.hash.value + '/' + route);
            this.$router.push('/prijava/' + this.form.hash.value + '/' + route);
          }
        })
        .catch((error) => { this.cmnAxiosError(error, true); })
        .then(() => { this.hideOverlay(); });
    },
    itemLabel(f) {
      if (!this.form[f].required) { return this.form[f].label + ' (*nije obavezno)' }
      return this.form[f].label;
    },
    showError(m) {
      this.showDialog({ text: m, app: true });
    }
  },

  watch: {
  },

  data() {
    return {
      form: {
        hash: { value: this.$route.params.hash },
        // dummy variable to handle null visible_condition field
        _at: { value: true }
      },
      //
      child_name: null,
      child_surname: null,
      child_pin: null,
      email: null,
      document_list: null,
      showForm: false,
      outlined: false,
      init: true,
      steps: null,
      p89: false
    }
  },

  components: {
    FileUpload,
    TheChildInfo,
    ThePageTitle
  }
};

</script>
