<template>
  <v-scale-transition>
  <div v-show="showForm">
    <div class="mt-10"></div>
    <div class="mb-10" v-if="forms_data.form_a">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">PODATCI O DJETETU</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6  elevation-6 rounded fluid nbr-mw800 ">
          <div v-for="(item, index) in forms_fields.form_a" :key="item.name" :index="index">
            <review-item
              v-if="item.name != 'child_city_n' && item.tags=='g1'"
              :item="item"
              :item_value="forms_data.form_a[item.name]"
            />
          </div>

          <div v-if="forms_data.form_a['family_D108']">
            <v-icon class="mb-1">mdi-file-document-outline</v-icon>
            <div>
              <review-document
              :item="forms_fields.form_a_dl['family_D108']"
              :item_value="forms_data.form_a['family_D108']"
              />
            </div>
          </div>

          <div v-if="forms_data.form_a['family_D116']">
            <v-icon class="mb-1">mdi-file-document-outline</v-icon>
            <div>
              <review-document
              :item="forms_fields.form_a_dl['family_D116']"
              :item_value="forms_data.form_a['family_D116']"
              />
            </div>
          </div>

          <div v-if="forms_data.form_a['D101']">
            <v-icon class="mb-1">mdi-file-document-outline</v-icon>
            <div>
              <review-document
              :item="forms_fields.form_a_dl['D101']"
              :item_value="forms_data.form_a['D101']"
              />
            </div>
          </div>

          <div v-if="forms_data.form_a['D102']">
            <v-icon class="mb-1">mdi-file-document-outline</v-icon>
            <div>
              <review-document
              :item="forms_fields.form_a_dl['D102']"
              :item_value="forms_data.form_a['D102']"
              />
            </div>
          </div>

          <div v-if="forms_data.form_a['D210']">
            <v-icon class="mb-1">mdi-file-document-outline</v-icon>
            <div>
              <review-document
              :item="forms_fields.form_a_dl['D210']"
              :item_value="forms_data.form_a['D210']"
              />
            </div>
          </div>

          <div v-if="forms_data.form_a['D120']">
            <v-icon class="mb-1">mdi-file-document-outline</v-icon>
            <div>
              <review-document
              :item="forms_fields.form_a_dl['D120']"
              :item_value="forms_data.form_a['D120']"
              />
            </div>
          </div>

          <div v-if="p93">
            <div v-for="(item, index) in forms_fields.form_a" :key="item.name + 'ag2'" :index="index">
              <review-item
                v-if="item.tags=='g2'"
                :item="item"
                :item_value="forms_data.form_a[item.name]"
              />
            </div>
          </div>
        </div>
      </v-row>
    </div>

    <div class="mb-10" v-if="forms_data.form_b">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">OBITELJSKI PODATCI</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 elevation-6 rounded fluid nbr-mw800 ">
          <div v-if="showMother">
            <div class="pt-6 pb-3 text-subtitle-1">PODATCI O MAJCI/SKRBNICI</div>
            <div v-for="(item, index) in forms_fields.form_b" :key="item.name + 'mg1'" :index="index">
              <review-item
                v-if="item.tags=='g1' && item.name != 'mother_city_n'"
                :item="item"
                :item_value="forms_data.form_b[item.name]"
              />
            </div>
            <div class="mt-3" v-if="forms_data.form_b['mother_D117']">
              <v-icon class="mb-1">mdi-file-document-outline</v-icon>
              <review-document
                :item="forms_fields.form_b_dl['mother_D117']"
                :item_value="forms_data.form_b['mother_D117']"
               />
            </div>
            <div v-for="(item, index) in forms_fields.form_b" :key="item.name + 'mg11'" :index="index">
              <review-item
                v-if="item.tags=='g11'"
                :item="item"
                :item_value="forms_data.form_b[item.name]"
              />
            </div>
            <div class="mt-3" v-if="forms_data.form_b['mother_D119']">
              <v-icon class="mb-1">mdi-file-document-outline</v-icon>
              <review-document
                :item="forms_fields.form_b_dl['mother_D119']"
                :item_value="forms_data.form_b['mother_D119']"
               />
            </div>
            <div class="mt-3" v-if="forms_data.form_b['mother_D118']">
              <v-icon class="mb-1">mdi-file-document-outline</v-icon>
              <review-document
                :item="forms_fields.form_b_dl['mother_D118']"
                :item_value="forms_data.form_b['mother_D118']"
               />
            </div>
            <div class="mt-3" v-if="forms_data.form_b['mother_D103']">
              <v-icon class="mb-1">mdi-file-document-outline</v-icon>
              <review-document
                :item="forms_fields.form_b_dl['mother_D103']"
                :item_value="forms_data.form_b['mother_D103']"
               />
            </div>
          </div>

          <div class='mt-3' v-if="showFather">
            <div class="pt-6 pb-3 text-subtitle-1">PODATCI O OCU/SKRBNIKU</div>
            <div v-for="(item, index) in forms_fields.form_b" :key="item.name + 'fg1'" :index="index">
              <review-item
                v-if="item.tags=='g2' && item.name != 'father_city_n'"
                :item="item"
                :item_value="forms_data.form_b[item.name]"
              />
            </div>
            <div class="mt-3" v-if="forms_data.form_b['father_D117']">
              <v-icon class="mb-1">mdi-file-document-outline</v-icon>
              <review-document
                :item="forms_fields.form_b_dl['father_D117']"
                :item_value="forms_data.form_b['father_D117']"
               />
            </div>
            <div v-for="(item, index) in forms_fields.form_b" :key="item.name + 'fg11'" :index="index">
              <review-item
                v-if="item.tags=='g21'"
                :item="item"
                :item_value="forms_data.form_b[item.name]"
              />
            </div>
            <div class="mt-3" v-if="forms_data.form_b['father_D119']">
              <v-icon class="mb-1">mdi-file-document-outline</v-icon>
              <review-document
                :item="forms_fields.form_b_dl['father_D119']"
                :item_value="forms_data.form_b['father_D119']"
               />
            </div>
            <div class="mt-3" v-if="forms_data.form_b['father_D118']">
              <v-icon class="mb-1">mdi-file-document-outline</v-icon>
              <review-document
                :item="forms_fields.form_b_dl['father_D118']"
                :item_value="forms_data.form_b['father_D118']"
               />
            </div>
            <div class="mt-3" v-if="forms_data.form_b['father_D103']">
              <v-icon class="mb-1">mdi-file-document-outline</v-icon>
              <review-document
                :item="forms_fields.form_b_dl['father_D103']"
                :item_value="forms_data.form_b['father_D103']"
               />
            </div>
          </div>


          <div class='mt-3' v-if="showFather || showMother">
            <div class="pt-6 pb-3 text-subtitle-1">OSNOVNI PODATCI O OBITELJI</div>
            <div v-for="(item, index) in forms_fields.form_b" :key="item.name + 'og2'" :index="index">
              <review-item
                v-if="item.tags=='g32' || item.tags=='g33'"
                :item="item"
                :item_value="forms_data.form_b[item.name]"
              />
            </div>
            <div class="mt-3" v-if="forms_data.form_b['family_D112']">
              <v-icon class="mb-1">mdi-file-document-outline</v-icon>
              <review-document
                :item="forms_fields.form_b_dl['family_D112']"
                :item_value="forms_data.form_b['family_D112']"
               />
            </div>
            <div v-for="(item, index) in forms_fields.form_b" :key="item.name + 'og3'" :index="index">
              <review-item
                v-if="item.tags=='g34' || item.tags=='g35'"
                :item="item"
                :item_value="forms_data.form_b[item.name]"
              />
            </div>
          </div>
        </div>
      </v-row>
    </div>

    <div class="mb-10" v-if="forms_data.form_c">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">ODABRANI VRTIĆ / PROGRAM</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 elevation-6 rounded fluid nbr-mw800 ">
          <div v-for="(item, index) in forms_fields.form_c" :key="item.name + 'g0'" :index="index">
             <review-item
               v-if="item.tags=='g0'"
               :item="item"
               :item_value="forms_data.form_c[item.name]"
             />
          </div>
          <div class="ma-3"></div>
          <div v-for="(item, index) in forms_fields.form_c" :key="item.name + 'g1'" :index="index">
             <review-item
               v-if="item.tags=='g1'"
               :item="item"
               :item_value="forms_data.form_c[item.name]"
             />
          </div>
          <div class="ma-3"></div>
          <div v-for="(item, index) of forms_fields.form_c" :key="item.name + 'g2'" :index="index">
             <review-item
               v-if="item.tags=='g2'"
               :item="item"
               :item_value="forms_data.form_c[item.name]"
             />
          </div>
          <div class="ma-3"></div>
          <div v-for="(item, index) of forms_fields.form_c" :key="item.name + 'g3'" :index="index">
             <review-item
               v-if="item.tags=='g3'"
               :item="item"
               :item_value="forms_data.form_c[item.name]"
             />
          </div>
        </div>
      </v-row>
    </div>

    <div class="mt-10" v-if="p89 && forms_data.questionnaire">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">UPITNIK ZA RODITELJE</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6  elevation-6 rounded fluid nbr-mw800">
          <div v-if="form_q_title.hasOwnProperty('q_g2_title')">
            <div class="pt-6 pb-3 text-subtitle-1">{{ form_q_title.q_g2_title }}</div>
            <div v-for="(item, index) in forms_fields.questionnaire" :key="item.name + 'g2'" :index="index">
              <review-item
                v-if="item.tags=='g2' && forms_data.questionnaire[item.name] && item.type!='document'"
                :item="item"
                :item_value="forms_data.questionnaire[item.name]"
              />
              <div v-if="item.type=='document' && item.tags=='g2' && forms_data.questionnaire[item.name]">
                <v-icon class="mb-1">mdi-file-document-outline</v-icon>
                <div>
                  <review-document
                  :item="forms_fields.questionnaire_dl[item.name]"
                  :item_value="forms_data.questionnaire[item.name]"
                 />
                </div>
              </div>
            </div>
          </div>

          <div v-if="form_q_title.hasOwnProperty('q_g3_title')">
            <div class="pt-6 pb-3 text-subtitle-1">{{ form_q_title.q_g3_title }}</div>
            <div v-for="(item1, index1) in forms_fields.questionnaire" :key="item1.name + 'g3'" :index="index1">
              <review-item
                v-if="item1.tags=='g3' && forms_data.questionnaire[item1.name] && item1.type!='document'"
                :item="item1"
                :item_value="forms_data.questionnaire[item1.name]"
              />
              <div v-if="item1.type=='document' && item1.tags=='g3' && forms_data.questionnaire[item1.name]">
                <v-icon class="mb-1">mdi-file-document-outline</v-icon>
                <div>
                  <review-document
                  :item="forms_fields.questionnaire_dl[item1.name]"
                  :item_value="forms_data.questionnaire[item1.name]"
                 />
                </div>
              </div>
            </div>
          </div>

          <div v-if="form_q_title.hasOwnProperty('q_g4_title')">
            <div class="pt-6 pb-3 text-subtitle-1">{{ form_q_title.q_g4_title }}</div>
            <div v-for="(item2, index2) in forms_fields.questionnaire" :key="item2.name + 'g4'" :index="index2">
              <review-item
                v-if="item2.tags=='g4' && forms_data.questionnaire[item2.name]"
                :item="item2"
                :item_value="forms_data.questionnaire[item2.name]"
              />
            </div>
          </div>

          <div v-if="form_q_title.hasOwnProperty('q_g5_title')">
            <div class="pt-6 pb-3 text-subtitle-1">{{ form_q_title.q_g5_title }}</div>
            <div v-for="(item3, index3) in forms_fields.questionnaire" :key="item3.name + 'g5'" :index="index3">
              <review-item
                v-if="item3.tags=='g5' && forms_data.questionnaire[item3.name]"
                :item="item3"
                :item_value="forms_data.questionnaire[item3.name]"
              />
            </div>
          </div>

          <div v-if="form_q_title.hasOwnProperty('q_g6_title')">
            <div class="pt-6 pb-3 text-subtitle-1">{{ form_q_title.q_g6_title }}</div>
            <div v-for="(item4, index4) in forms_fields.questionnaire" :key="item4.name + 'g6'" :index="index4">
              <review-item
                v-if="item4.tags=='g6' && forms_data.questionnaire[item4.name]"
                :item="item4"
                :item_value="forms_data.questionnaire[item4.name]"
              />
            </div>
          </div>
        </div>
      </v-row>
    </div>

    <div class="mb-10 mt-10" v-if="showDocuments">
      <v-row class="justify-center">
        <div class="blue lighten-5 mb-2 mb-sm-2 elevation-6 rounded fluid nbr-mw800">
          <div class="text-center ma-2 text-sm-h6">DODATNI DOKUMENTI</div>
        </div>
      </v-row>
      <v-row class="justify-center">
        <div class="pa-6 elevation-6 rounded fluid nbr-mw800 ">
          <v-icon class="mb-1">mdi-file-document-outline</v-icon>
          <div v-for="(item, index) in forms_fields.document" :key="item.document_type_code" :index="index">
            <div v-if="forms_data.document[item.document_type_code]">
              <review-document
               v-if="forms_data.document[item.document_type_code].length"
               :item="item"
               :item_value="forms_data.document[item.document_type_code]"
              />
            </div>
            <div class="pa-2" v-if="forms_data.document[item.document_type_code]"></div>
          </div>
        </div>
      </v-row>
    </div>
 </div>
</v-scale-transition>
</template>

<script>
import ReviewItem from '../components/ReviewItem';
import ReviewDocument from '../components/ReviewDocument';
import axios from 'axios';
import common from '../common.js';

export default {
  name: 'ReviewComponent',
  mixins: [ common ],
  created () {
    this.getFormData();
  },

  computed: {
    denseField () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        default: return false;
      }
    }
  },

  methods: {
    getFormData() {
      this.showOverlay();
      axios.post('', { action: 'getFormReviewComponent', hash: this.form.hash.value })
        .then((res) => {
          if (this.cmnAxiosPost(res)) {
            this.p89 = eval(res.data.p89);
            this.p93 = eval(res.data.p93);
            //
            this.forms_fields = res.data.forms_fields;
            this.forms_data = res.data.forms_data;
            for (let i=0; i<this.forms_fields.questionnaire.length; i++) {
              if (this.forms_fields.questionnaire[i].tags == 'title') {
                this.form_q_title[this.forms_fields.questionnaire[i].name] = this.forms_fields.questionnaire[i].label;
              }
            }
          }
        })
        .catch((error) => { this.cmnAxiosError(error, false); })
        .then(() => {
          this.hideOverlay();
          this.showForm = true;
          this.init = false;
        });
    }
  },

  watch: {
    'forms_data.form_a.child_family_status.value': { immediate: true, handler(n) {
        if (n == 110 || n == 111 || n == 120 || n == 130 || n == 121) this.showMother = true;
        if (n == 110 || n == 111 || n == 130 || n == 120 || n == 131) this.showFather = true;
      }
    },
    'forms_data.document': { immediate: true, handler(n) {
        if (typeof n === 'object') {
          if (Object.keys(n).length > 1) {
            this.showDocuments = true;
          }
        }
        else {
          this.showDocuments = false;
        }
      }
    }
  },

  data() {
    return {
      form: {
        hash: { value: this.$route.params.hash }
      },
      //
      forms_fields: {},
      forms_data: {},
      form_q_title: {},
      email: null,
      showForm: false,
      showFather: false,
      showMother: false,
      showDocuments: false,
      p89: false,
      p93: false,
      init: true
    }
  },

  components: {
    ReviewDocument,
    ReviewItem
  }
};

</script>
